import Vue from 'vue'
import App from './App.vue'
import router from './router'
import widgetServices from './tmuWidget'
import {globals} from './globals'
import AudioRecorderPolyfill from "audio-recorder-polyfill";
window.MediaRecorder = AudioRecorderPolyfill;
Vue.config.productionTip = false;
Vue.prototype.$ttsHost = (process.env&&process.env.VUE_APP_SPEECH_HOST)? process.env.VUE_APP_SPEECH_HOST: '';
(function (){
    function initApp(){
        Vue.mixin({
            data() {
                return { globals: {},  widgetServices: null, }
            },
            mounted(){
                this.globals = globals;
                if(this.$el) {
                    if(this.globals.isCustom)
                        this.$el.classList.add('is-custom');
                    this.$el.classList.remove('display-none');
                }
                this.widgetServices = widgetServices;
            },
            methods: {
                checkEmailValidity(email) {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email.toLowerCase());
                },
                checkJson(str) { try { return typeof JSON.parse(str) === 'object' ? JSON.parse(str) : false; } catch (e) { return false; } },
                removeEmojis(str) {
                    return str.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}]/gu, '');
                }
            }
        });
        new Vue({
            router,
            render: h => h(App)
        }).$mount('#app')
    }
    if(!globals.isWidgetEventSet){
        widgetServices.init();
        widgetServices.initWidget((data, err) => {
            if(data) {
                const query = window.location.search;
                const params = new URLSearchParams(query);
                let isCustom = +params.get('custom')===1;
                if(isCustom)
                    globals.isCustom = true;
                globals.isWidgetEventSet = true;
                globals.botInfo = JSON.parse(data);
                if(globals.isCustom) {
                    //break any color or background if coming from theme
                    for(let k in globals.botInfo) {
                        if(k.indexOf('color')>-1 || k.indexOf('background')>-1 || k.indexOf('size')>-1)
                            globals.botInfo[k] = 'auto';
                    }
                }
                let style = document.createElement("style");
                document.head.appendChild(style);
                try { style.innerHTML = `.conversation a {color: ${globals.botInfo.widget_link_color}; }`; }
                catch (_ie) { style['styleSheet'].cssText = `.conversation a { color: ${globals.botInfo.widget_link_color}; }`; }
                initApp();
            } else console.log(err)
        })
    }
})()
