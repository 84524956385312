import {globals} from "@/globals";

export default (function (){
    const _callbacks = {};
    const service = {};
    function messageHandler(event) {
        if(!event || !event.data || event.data.requester !== 'tmu.widget.support.mresence') return;
        if (event.data.action === 'eventFulfilled') {
            if (typeof event.data.eventName === 'string' && event.data.eventName.trim()) {
                let eventName = event.data.eventName;
                if(typeof _callbacks[eventName] === 'function'){
                    if (event.data.status === 'fail') { _callbacks[eventName](null, event.data.error); }
                    if (event.data.status === 'success') { _callbacks[eventName](event.data.data, null); }
                    delete _callbacks[eventName];
                } else if(eventName === 'chatWithBot' && event.data.status === 'success' && globals.BotPage){
                    if(!globals.messages.length && !globals.isChatStarted){
                        globals.isChatStarted = true;
                        if(Array.isArray(globals.botInfo.ads)){
                            globals.botInfo.ads = globals.botInfo.ads.map(ad => ({...ad, isActive: false}))
                            for(const ad of globals.botInfo.ads){
                                setTimeout(() => { ad.isActive = true; }, (+ad.timer ? +ad.timer : 10) * 1000)
                            }
                        }
                    }
                    globals.BotPage.addToChat(event.data.data).then(() => {}).catch(() => {});
                }
            }
        }
        else if(event.data.action === 'requestDownloadModal' && globals.messages.length){ globals.BotPage.showDownloadModal(); }
        else if(event.data.action === 'changeLanguage' && event.data.language){ globals.BotPage.updateLanguage(event.data.language, event.data.UIText); }
        else if (event.data.action === 'restoreMessages'){
            try{
                if(!globals.BotPage){
                    let checkBotPageLoadingInv = setInterval(() => {
                        if(globals.BotPage) {
                            clearInterval(checkBotPageLoadingInv);
                            // globals.BotPage.isEmergencyHandled = true;
                            globals.BotPage.updateLanguage(event.data.currentLang);
                            globals.messages = JSON.parse(event.data.messages);
                            globals.valueToPassWaiting = event.data.valueToPassWaiting;
                            if(globals.messages.length) service.showConvOptions();
                            service.enableLanguages();
                            globals.BotPage.initChatBot();
                        }
                    })
                }
            } catch (e) { console.log(e)}

        }
        if(globals.BotPage) {
            if(event.data.action === 'stopChatGPT'){ globals.BotPage.stopChatGPT(true); }
            else if (event.data.action === 'showEmergencyModal'){ globals.BotPage.showEmergencyModal(); }
            else if (event.data.action === 'restartBotConversation'){ globals.BotPage.getNewUser() }
            else if(event.data.action === 'toggleSpeaker') {
                if (event.data.status === 'on') {
                    globals.BotPage.isSpeakerOn = true;
                    globals.BotPage.startSpeaker();
                } else {
                    globals.BotPage.isSpeakerOn = false;
                    globals.BotPage.stopSpeaker();
                }
            }
            else if(event.data.action === 'startRecording'){ globals.BotPage.startRecording() }
            else if(event.data.action === 'stopRecording'){ globals.BotPage.stopRecording() }
        }
    }
    service.init = () => {
        window.removeEventListener('message', messageHandler);
        window.addEventListener('message', messageHandler);
    };
    service.redirectToCall = ({callInfo, cb}) => {
        const eventName = 'redirectToCall';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': callInfo,
            'messages': JSON.stringify(globals.messages),
            'valueToPassWaiting': globals.valueToPassWaiting,
            'eventName': eventName
        }, '*');
        _callbacks[eventName] = cb;
    };
    service.initWidget = (cb) => {
        const eventName = 'initWidget';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
        _callbacks[eventName] = cb;
    }
    service.hideConvOptions = () => {
        const eventName = 'hideConvOptions';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
    }
    service.showConvOptions = () => {
        const eventName = 'showConvOptions';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
    }
    service.showEmergencyBtn = () => {
        const eventName = 'showEmergencyBtn';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
    }
    service.hideEmergencyBtn = () => {
        const eventName = 'hideEmergencyBtn';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
    }
    service.disableLanguages = () => {
        const eventName = 'disableLanguages';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
    }
    service.enableLanguages = () => {
        const eventName = 'enableLanguages';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
    }
    service.requestChangeLanguageFromBot = (lang) => {
        const eventName = 'requestChangeLanguage';
        parent.postMessage({
            'data': lang,
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
    }
    // service.rememberAudioNoticeVisibility = () => {
    //     const eventName = 'rememberAudioNoticeVisibility';
    //     parent.postMessage({
    //         'requester': 'tmu.widget.support.mresence',
    //         'eventName': eventName
    //     }, '*');
    // }
    service.setupForChatGPT = () => {
        const eventName = 'setupForChatGPT';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName
        }, '*');
    }
    service.stopChatGPT = (continueConversation = true) => {
        const eventName = 'stopChatGPT';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName,
            'continueConversation': continueConversation
        }, '*');
    }
    service.loadChatHistory = (cb) => {
        const eventName = 'loadChatHistory';
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName,
        }, '*');
        _callbacks[eventName] = cb;
    }
    service.startNewConversation = ({cb}) => {
        const eventName = 'startNewConversation';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName}, '*');
        _callbacks[eventName] = cb;
    }
    service.onCallRequested = ({providerId, visitor_name, subject, description, cb}) => {
        const eventName = 'callRequested';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': JSON.stringify({providerId, description, subject, visitor_name}),
            'eventName': eventName}, '*');
        _callbacks[eventName] = cb;
    }
    service.onGuestWaited = ({guest_name, subject, description, email, conversation, cb}) => {
        const eventName = 'guestWaited';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': JSON.stringify({guest_name, subject, description, email, conversation}),
            'eventName': eventName
        }, '*');
        _callbacks[eventName] = cb;
    }
    // service.onCallStarted = ({callId, profileId, isCall, cb}) => {
    //     const eventName = 'callStarted';
    //     if(_callbacks[eventName]) return;
    //     parent.postMessage({
    //         'requester': 'tmu.widget.support.mresence',
    //         'data': JSON.stringify({callId, profileId, isCall}),
    //         'eventName': eventName }, '*');
    //     _callbacks[eventName] = cb;
    // }
    service.onCallChatToggled = ({callStat, cb}) => {
        const eventName = 'callChatToggled';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': callStat,
            'eventName': eventName }, '*');
        _callbacks[eventName] = cb;
    }
    // service.onCallFinished = ({callId, cb}) => {
    //     const eventName = 'callFinished';
    //     if(_callbacks[eventName]) return;
    //     parent.postMessage({
    //         'requester': 'tmu.widget.support.mresence',
    //         'data': JSON.stringify({ callId: callId }),
    //         'eventName': eventName }, '*');
    //     _callbacks[eventName] = cb;
    // }
    service.chatWithBot = ({text, lastNode, ignoreSaving, isHidden, cb}) => {
        const eventName = 'chatWithBot';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': text,
            'lastNode': lastNode,
            'ignoreSaving': ignoreSaving,
            'isHidden': isHidden,
            'eventName': eventName }, '*');
        _callbacks[eventName] = cb;
    }
    service.sendBotFile = ({blob, ext, cb}) => {
        const eventName = 'sendBotFile';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': blob,
            'ext': ext,
            'eventName': eventName }, '*');
        _callbacks[eventName] = cb;
    }
    service.getFeedbacksList = ({feedbackId, cb}) => {
        const eventName = 'getFeedbacksList';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': JSON.stringify({groupId: feedbackId}),
            'eventName': eventName}, '*');
        _callbacks[eventName] = cb;
    }
    service.showRateIcon = (cb) => {
        const eventName = 'showRateIcon';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName}, '*');
        _callbacks[eventName] = cb;
    }
    service.showDownloadIcon = (cb) => {
        const eventName = 'showDownloadIcon';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName}, '*');
        _callbacks[eventName] = cb;
    }
    service.sendFeedbackAnswers = ({feedbackId, qnaFeedback = [], cb}) => {
        const eventName = 'sendFeedbackAnswers';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': JSON.stringify({ groupId: feedbackId, qna: qnaFeedback }),
            'eventName': eventName}, '*');
        _callbacks[eventName] = cb;
    }
    service.downloadConversation = ({email, conversation, cb}) => {
        const eventName = 'downloadConversation';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': JSON.stringify({ email, conversation }),
            'eventName': eventName
        }, '*');
        _callbacks[eventName] = cb;
    }
    service.checkServiceAuthority = ({serviceName, cb}) => {
        const eventName = 'checkServiceAuthority';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': JSON.stringify({ serviceName  }),
            'eventName': eventName
        }, '*');
        _callbacks[eventName] = cb;
    }
    service.validateRequestId = ({requestId, cb}) => {
        const eventName = 'validateRequestId';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': JSON.stringify({ requestId  }),
            'eventName': eventName
        }, '*');
        _callbacks[eventName] = cb;
    }
    service.chargeUsage = ({requestId, startTime, endTime, cb}) => {
        const eventName = 'chargeUsage';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'data': JSON.stringify({ requestId, startTime, endTime }),
            'eventName': eventName
        }, '*');
        _callbacks[eventName] = cb;
    }
    service.tts = ({text, lang, cb}) => {
        const eventName = 'tts';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'text': text,
            'lang': lang,
            'eventName': eventName }, '*');
        _callbacks[eventName] = cb;
    }
    service.stt = ({audioFile, lang, cb}) => {
        const eventName = 'stt';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'audioFile': audioFile,
            'lang': lang,
            'eventName': eventName }, '*');
        _callbacks[eventName] = cb;
    }
    service.sendGeoLocationPosition = (cb) => {
        const eventName = 'sendGeoLocationPosition';
        if(_callbacks[eventName]) return;
        parent.postMessage({
            'requester': 'tmu.widget.support.mresence',
            'eventName': eventName}, '*');
        _callbacks[eventName] = cb;
    }
    // service.setAudioToReady = () => {
    //     const eventName = 'setAudioToReady';
    //     parent.postMessage({
    //         'requester': 'tmu.widget.support.mresence',
    //         'eventName': eventName}, '*');
    // }
    // service.hideAudioIcons = () => {
    //     const eventName = 'hideAudioIcons';
    //     parent.postMessage({
    //         'requester': 'tmu.widget.support.mresence',
    //         'eventName': eventName}, '*');
    // }
    return service;
})();


