/*eslint-disable*/
const globals = {
    active_page: 'bot',
    BotPage: null,
    valueToPassWaiting: "",
    messages: [],
    history: [],
    botInfo: null,
    isWidgetEventSet: false,
    botChatTimeout: null,
    isChatEnabled: true,
    isCustom: false,
    botAnimation: ['animate__backInDown'],
    userAnimation: ['animate__backInUp'],
    choicesAnimation: ['animate__tada', 'animate__bounce'],
    chatBoxContainerId: 'chatbox-options',
    chatBoxContainer: null,
    historyContainerId: 'history-container',
    historyContainer: null,
    activeContainerId: 'active-container',
    activeContainer: null,
    mainInputId: 'tmu-bot-input',
    mainInputElement: null,
    isInputOnFocus: false,
    isChatStarted: false,
    initContainers: function() {
        this.chatBoxContainer = document.getElementById(this.chatBoxContainerId);
        this.historyContainer = document.getElementById(this.historyContainerId);
        this.activeContainer = document.getElementById(this.activeContainerId);
    },
    addMessage: function(obj) {
        if(!obj)
            return;
        if(this.isCustom && !obj.cssAnimation) {
            if(Array.isArray(obj.choices)) {
                for(let i=0; i<obj.choices.length; i++) {
                    obj.choices[i].cssAnimation = this.choicesAnimation[0];
                }
            } else {
                obj.cssAnimation = obj.from=='bot'? this.botAnimation[0]: this.userAnimation[0];
            }
        }
        if(this.isCustom) {
            if(this.activeContainer && !this.activeContainer.style.zIndex) {
                this.activeContainer.style.zIndex = '1';
            }
            this.disableHistory();
            clearTimeout(this.onEnableHistoryContainerTimer);
            this.onEnableHistoryContainerTimer = setTimeout(()=>{
                this.enableHistory();
            }, 500);
        }
        this.messages.push(obj);
        if(this.isCustom) {
            if(this.messages.length>2 && this.messages.filter(item=>item.from=='user').length>0) {
                for(let i=0; i<this.messages.length; i++) {
                    if(this.messages[i].from=='bot')
                        this.messages[i].scrollAnimate = 'fade-right';
                    else
                        this.messages[i].scrollAnimate = 'fade-left';
                    this.history.push(this.messages[i]);
                    this.messages.splice(i, 1);
                    break;
                }
            }
            setTimeout(()=>{
                this.scrollDown();
            })
        }
    },
    onAddMessage: function(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj) {
                reject();
                return;
            }
            if(obj.isChoice && obj.from=='user') {
                obj.cssAnimation = 'animate__backInUp';
            }
            this.addMessage(obj);
            setTimeout(()=>{
                resolve();
            }, this.isCustom? 750: 0);
        })
    },
    disableHistory: function() {
        if(!this.isCustom)
            return;
        if(this.historyContainer)
            this.historyContainer.classList.add('display-none');
    },
    enableHistory: function() {
        if(!this.isCustom)
            return;
        if(this.historyContainer)
            this.historyContainer.classList.remove('display-none');
    },
    selectChoice: function(obj) {
        if(obj && this.isCustom)
            obj.cssAnimation = 'animate__backOutUp';
    },
    scrollDown: function() {
        let chatBox = document.querySelector('.chatbot.is-custom');
        if(!chatBox) return;
        chatBox.scrollTop = chatBox.scrollHeight;
    },
    handleHistoryVisible: function(e) {
        if(!this.isCustom)
            return;
        if(this.historyContainer) {
            setTimeout(()=>{
                if(!e || !e.target)
                    return;
                for(let i=0; i<this.historyContainer.children.length; i++) {
                    let row = this.historyContainer.children[i];
                    if(Math.abs(e.target.scrollTop) < Math.abs(row.offsetTop))
                        continue;
                    if(!row.classList.contains('chat-row')
                    || row.classList.contains('animate__animated'))
                        continue;
                    row.classList.remove('visibility-hidden');
                    if(row.classList.contains('bot'))
                        row.classList.add('animate__animated', 'animate__backInLeft')
                    if(row.classList.contains('user'))
                        row.classList.add('animate__animated', 'animate__backInRight');
                }
            }, 200);
        }
    },
    blurMainInput: function() {
        if(!this.mainInputElement)
            this.mainInputElement = document.getElementById(this.mainInputId);
        if(this.mainInputElement)
            this.mainInputElement.blur();
    },
    focusOnMainInput: function() {
        if(!this.mainInputElement)
            this.mainInputElement = document.getElementById(this.mainInputId);
        if(this.mainInputElement)
            this.mainInputElement.focus();
    }
}
export {globals}
